import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";
import { store } from "../store";
import { REMOVE_ALL_ORDER_ITEMS } from "./cartActions";
import { fetchWithSessionHandling } from "../../helpers/sessionHandling/fetchWithSessionHandling";

const config = new Config();

export const SET_ORDERTYPE = "SET_ORDERTYPE";

export function setOrdertype(orderType) {
    return {
        type: SET_ORDERTYPE,
        orderType: orderType,
    };
}

export const SET_ORDERTYPE_BY_ID = "SET_ORDERTYPE_BY_ID";

export function setOrdertypeById(orderTypeId) {
    store.dispatch({ type: REMOVE_ALL_ORDER_ITEMS });
    return {
        type: SET_ORDERTYPE_BY_ID,
        orderTypeId: orderTypeId,
    };
}

export const SET_CUSTOMER = "SET_CUSTOMER";

export function setCustomer(customer) {
    return {
        type: SET_CUSTOMER,
        customer: customer,
    };
}

export const SET_CUSTOMERCOURSES = "SET_CUSTOMERCOURSES";

export function setCustomerCourses(customerCourses) {
    return {
        type: SET_CUSTOMERCOURSES,
        customerCourses: customerCourses,
    };
}

export function loginCustomer(email, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, password: password }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/loginCustomer", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => store.dispatch(setCustomer(json)));
    };
}

export function getCustomer(token) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            shopIdentification: config.biosShopToken,
        },
    };

    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/getCustomerFromOnlineShop`, requestOptions)
            .then((response) => response.json())
            .catch((error) => {
                console.error("Error fetching customer data:", error);
            });
    };
}

export function getCustomerAddresses(token) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            shopIdentification: config.biosShopToken,
        },
    };

    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/getCustomerAddresses`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response;
            })
            .catch((error) => {
                console.error("Error fetching customer data:", error);
                throw error; // re-throw the error to be caught by the calling function
            });
    };
}

export function createCustomerInBIOS(keycloak) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
        body: JSON.stringify(keycloak),
    };

    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/createUserInBIOS`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response;
            })
            .catch((error) => {
                console.error("Error fetching customer data:", error);
            });
    };
}

// export function getCustomer(azureId) {
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(azureId),
//     };
//     return function (dispatch) {
//         return fetch(config.backendHost + "/Customers/GetCustomerFromOnlineShop", requestOptions).then((response) =>
//             handleResponse(response)
//         );
//     };
// }

// export function updateAddress(
//     customerUid,
//     title,
//     firstName,
//     lastName,
//     addressIndex,
//     city,
//     street,
//     houseNumber,
//     zipCode,
//     company,
//     addressDetails,
//     isDefaultBillingAddress,
//     isDefaultDeliveryAddress,
//     addressUid
// ) {
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//             customerUid: customerUid,
//             title: title,
//             firstName: firstName,
//             lastName: lastName,
//             addressIndex: addressIndex,
//             city: city,
//             street: street,
//             houseNumber: houseNumber,
//             zipCode: zipCode,
//             company: company,
//             addressDetails: addressDetails,
//             isDefaultDeliveryAddress: isDefaultDeliveryAddress,
//             isDefaultBillingAddress: isDefaultBillingAddress,
//             addressUid: addressUid,
//         }),
//     };
//     return function (dispatch) {
//         return fetch(config.backendHost + "/customers/updateAddress", requestOptions).then((response) =>
//             handleResponse(response)
//         );
//     };
// }

export function updateAddress(
    customerUid,
    title,
    firstName,
    lastName,
    // addressIndex,
    city,
    street,
    houseNumber,
    zipCode,
    company,
    addressDetails,
    isDefaultBillingAddress,
    isDefaultDeliveryAddress,
    addressUid,
    addressId,
    countryId,
    keycloak
) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
        body: JSON.stringify({
            customerUid: customerUid,
            title: title,
            firstName: firstName,
            lastName: lastName,
            // addressIndex: addressIndex,
            city: city,
            street: street,
            houseNumber: houseNumber,
            zipCode: zipCode,
            company: company,
            addressDetails: addressDetails,
            isDefaultDeliveryAddress: isDefaultDeliveryAddress,
            isDefaultBillingAddress: isDefaultBillingAddress,
            addressUid: addressUid,
            addressId: addressId ? addressId : -1,
            countryId,
        }),
    };

    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/updateAddress`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((err) => {
                        throw new Error(err.message || "Something went wrong");
                    });
                }
                return response.json();
            })
            .catch((error) => {
                console.error("Error updating customer data:", error);
            });
    };
}

// export function deleteAddress(customerUid, addressIndex, city, street, houseNumber, zipCode, addressUid) {
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//             customerUid: customerUid,
//             addressIndex: addressIndex,
//             city: city,
//             street: street,
//             houseNumber: houseNumber,
//             zipCode: zipCode,
//             addressUid: addressUid,
//         }),
//     };
//     return function (dispatch) {
//         return fetch(config.backendHost + "/customers/deleteAddress", requestOptions).then((response) =>
//             handleResponse(response)
//         );
//     };
// }

export function deleteAddress(customerUid, addressIndex, houseNumber, addressUid, addressId, keycloak) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
        body: JSON.stringify({
            customerUid: customerUid,
            addressIndex: addressIndex,
            houseNumber: houseNumber,
            addressUid: addressUid,
        }),
    };
    return function (dispatch) {
        return fetchWithSessionHandling(config.apiUrl + `/deleteAddress/${addressId}`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((err) => {
                        throw new Error(err.message || "Something went wrong");
                    });
                }
                return response.json();
            })
            .catch((error) => {
                console.error("Error updating customer data:", error);
            });
    };
}

export function registerCustomer(customer) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customer }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/registerCustomer", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

// export function updateStandardBillingAddress(customerUid, addressIndex) {
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ customerUid: customerUid, addressIndex: addressIndex }),
//     };
//     return function (dispatch) {
//         return fetch(config.backendHost + "/Customers/UpdateStandardBillingAddressFromOnlineShop", requestOptions).then(
//             (response) => handleResponse(response)
//         );
//     };
// }

export function updateStandardBillingAddress(addressId, keycloak) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetchWithSessionHandling(
            config.apiUrl + `/updateStandardBillingAddressFromOnlineShop/${addressId}`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

// export function updateStandardDeliveryAddress(customerUid, addressIndex) {
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ customerUid: customerUid, addressIndex: addressIndex }),
//     };
//     return function (dispatch) {
//         return fetch(
//             config.backendHost + "/Customers/UpdateStandardDeliveryAddressFromOnlineShop",
//             requestOptions
//         ).then((response) => handleResponse(response));
//     };
// }

export function updateStandardDeliveryAddress(addressId, keycloak) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetchWithSessionHandling(
            config.apiUrl + `/updateStandardDeliveryAddressFromOnlineShop/${addressId}`,
            requestOptions
        ).then((response) => handleResponse(response));
    };
}

export function getShoppingList(from, to, keycloak) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetchWithSessionHandling(
            `${config.apiUrl}/getShoppingList/${from.toISOString()}/${to.toISOString()}`,
            requestOptions
        ).then(
            (response) => handleResponse(response),
            (err) => console.log(err)
        );
    };
}

export function checkShoppingItem(cookingProcessIngredient) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
        body: JSON.stringify(cookingProcessIngredient),
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/checkShoppingItem`, requestOptions).then((response) => handleResponse(response));
    };
}

export function GetCoursesByCustomer(customersUid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(customersUid),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Customers/GetCoursesByCustomer", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export function deleteCustomerCourse(customerCoursesId, keycloak) {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/deleteCustomerCourse/${customerCoursesId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export function updateCustomerCourses(keycloak, courses) {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
        body: JSON.stringify({ courses: courses }),
    };
    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/updateCustomerCourses`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export function createCustomerCourses(coursesId, keycloak) {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/createCustomerCourses/${coursesId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export function updateOnlineShopCustomer(title, firstName, lastName, phoneNumbers, keycloak) {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
        body: JSON.stringify({
            title: title,
            firstName: firstName,
            lastName: lastName,
            phoneNumbers: phoneNumbers,
        }),
    };
    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/updateCustomerInfo`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => store.dispatch(setCustomerCourses(json)));
    };
}

export async function getOrderHistory(keycloak) {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
        },
    };

    try {
        const response = await fetchWithSessionHandling(`${config.apiUrl}/getOrderHistory`, requestOptions);
        return handleResponse(response);
    } catch (error) {
        console.error("Error fetching order history:", error);
        throw error;
    }
}

// export function updateOnlineShopCustomer(customerUid, azureId, firstName, lastName, phoneNumber) {
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//             customerUid: customerUid,
//             azureId: azureId,
//             firstName: firstName,
//             lastName: lastName,
//             phoneNumber: phoneNumber,
//         }),
//     };
//     return function (dispatch) {
//         return fetch(config.backendHost + "/Customers/updateOnlineShopCustomer", requestOptions)
//             .then((response) => handleResponse(response))
//             .then((json) => store.dispatch(setCustomerCourses(json)));
//     };
// }
